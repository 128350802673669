.myAuthenticatorClass {
    background-color: #203764 !important;
    background-size: cover !important;
    height: 120vh;
    width: 100%;
    -moz-background-size: cover !important;
    -webkit-background-size: cover !important;
    -o-background-size: cover !important;
}

.amplify-button {
    background: #111C7D !important;
    color: rgb(255, 255, 255) !important;
    opacity: 0.9 !important;
}

.myAuthenticatorClass.background-image {
    opacity: 0.1 !important;
}

.amplify-tabs__item {
    color: #111C7D !important;
    border-color: #111C7D !important;
    opacity: 0.9 !important;
}

.amplify-heading {
    color: #111C7D !important;
    opacity: 0.8 !important;
}

.amplify-label {
    color: #111C7D !important;
    opacity: 0.8 !important;
}

